<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="serverData"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row flex-grow-0 flex-shrink-0">
          <div
            class="col-12 col-sm-6 h-75px"
            v-if="serverData.hasOwnProperty('organizations')"
          >
            <v-autocomplete
              v-model="formData.organization_id"
              label="Organization"
              item-text="text"
              item-value="index"
              :items="serverData.organizations"
              clearable
              outlined
              dense
              :error-messages="organization_idErrors"
              @blur="$v.formData.organization_id.$touch()"
            />
          </div>

          <div
            class="col-12 h-75px"
            :class="{ 'col-md-6': serverData.hasOwnProperty('organizations') }"
            v-if="!isClient && serverData.hasOwnProperty('clients')"
          >
            <v-autocomplete
              v-model="formData.customer_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="serverData.clients"
              clearable
              outlined
              dense
              :error-messages="customer_idErrors"
              @blur="$v.formData.customer_id.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="formData.sku_id"
              label="SKU"
              item-text="systemCode"
              item-value="skuId"
              no-filter
              clearable
              outlined
              dense
              :items="skuItems"
              :loading="isLoading"
              :search-input.sync="search"
              hide-no-data
              hide-selected
              :error-messages="sku_idErrors"
              @blur="$v.formData.sku_id.$touch()"
            />
          </div>

          <div class="col-12 col-md-4 h-75px">
            <v-autocomplete
              v-model="formData.uom_id"
              label="UOM"
              item-text="text"
              item-value="index"
              :items="serverData.uoms"
              clearable
              outlined
              dense
              :error-messages="uom_idErrors"
              @blur="$v.formData.uom_id.$touch()"
            />
          </div>

          <div class="col-12 col-md-4 h-75px">
            <v-text-field
              v-model.number="formData.qty"
              label="Quantity"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              :error-messages="qtyErrors"
              @blur="
                () => {
                  validateMinValue('qty', 0, 'formData');
                  $v.formData.qty.$touch();
                }
              "
            />
          </div>

          <div class="col-12 col-md-4 h-75px">
            <v-autocomplete
              v-model="formData.base_uom_id"
              label="Base UOM"
              item-text="text"
              item-value="index"
              :items="serverData.base_uoms"
              clearable
              outlined
              dense
              :error-messages="base_uom_idErrors"
              @blur="$v.formData.base_uom_id.$touch()"
            />
          </div>

          <div class="col-12 col-md-4 h-75px">
            <v-text-field
              v-model.number="formData.length"
              label="Length"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              @blur="() => validateMinValue('length', 0, 'formData')"
            />
          </div>

          <div class="col-12 col-md-4 h-75px">
            <v-text-field
              v-model.number="formData.width"
              label="Width"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              @blur="() => validateMinValue('width', 0, 'formData')"
            />
          </div>

          <div class="col-12 col-md-4 h-75px">
            <v-text-field
              v-model.number="formData.height"
              label="Height"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              @blur="() => validateMinValue('height', 0, 'formData')"
            />
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.pack_material"
              label="Pack material"
              dense
              outlined
              clearable
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-text-field
              :value="volume"
              label="Volume"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              @blur="() => validateMinValue('volume', 0, 'formData')"
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.volume_uom_id"
              label="Volume UOM"
              item-text="text"
              item-value="index"
              :items="serverData.volume_uoms"
              clearable
              outlined
              dense
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-text-field
              v-model.number="formData.unit_price"
              label="Unit price"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              @blur="() => validateMinValue('unit_price', 0, 'formData')"
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.currency_id"
              label="Currency"
              item-text="text"
              item-value="index"
              :items="serverData.currencies"
              clearable
              outlined
              dense
            />
          </div>

          <div class="col-12 col-md-4 h-75px">
            <v-text-field
              v-model.number="formData.net_weight"
              label="Net weight"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              @blur="() => validateMinValue('net_weight', 0, 'formData')"
            />
          </div>

          <div class="col-12 col-md-4 h-75px">
            <v-text-field
              v-model.number="formData.gross_weight"
              label="Gross weight"
              type="number"
              :min="0"
              dense
              outlined
              clearable
              @blur="() => validateMinValue('gross_weight', 0, 'formData')"
            />
          </div>

          <div class="col-12 col-md-4 h-75px">
            <v-autocomplete
              v-model="formData.weight_uom_id"
              label="Weight UOM"
              item-text="text"
              item-value="index"
              :items="serverData.weight_uoms"
              clearable
              outlined
              dense
            />
          </div>
        </div>

        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { minValue, required, requiredIf } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { SET_ACTIONS_DIALOG_SERVER_DATA } from "@/core/services/store/uomConversions.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import { getToken } from "@/core/services/jwt.service";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      organization_id: {
        required: requiredIf(function () {
          return this.serverData.hasOwnProperty("organizations");
        }),
      },
      customer_id: {
        required: requiredIf(function () {
          return this.serverData.hasOwnProperty("clients");
        }),
      },
      sku_id: { required },
      uom_id: { required },
      base_uom_id: { required },
      qty: { required, minValue: minValue(0) },
    },
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    search: null,
    entries: [],
    typingTimer: null,
    formData: {
      organization_id: null,
      customer_id: null,
      sku_id: null,
      uom_id: null,
      qty: null,
      base_uom_id: null,
      length: null,
      width: null,
      height: null,
      pack_material: null, //text
      volume: null,
      volume_uom_id: null,
      unit_price: null,
      currency_id: null,
      net_weight: null,
      gross_weight: null,
      weight_uom_id: null,
    },
  }),
  methods: {
    loadDataFromServer() {
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post(
          "/warehouse_management/custom/uom_conversions/edit",
          sendData
        )
          .then(({ data }) => {
            this.dialog = true;
            if (!this.serverData) {
              this.$store.commit(SET_ACTIONS_DIALOG_SERVER_DATA, data);
            }
            this.formData = {
              id: sendData.id,
              ...data.uom_conversion,
            };

            this.entries = data.skus.map((sku) => ({
              skuId: sku.index,
              systemCode: sku.text,
            }));

            this.dialog = true;
            this.pageLoader(false);
          })
          .catch(() => {
            // console.log("err", err);
            this.dialog = false;
            this.pageLoader(false);
          })
          .finally(() => {
            // this.$refs.jsonEditor.updateProps();
          });
      } else {
        if (!this.serverData) {
          this.pageLoader(true);
          ApiService.post("/warehouse_management/custom/uom_conversions/create")
            .then(({ data }) => {
              this.$store.commit(SET_ACTIONS_DIALOG_SERVER_DATA, {
                clients: data.clients,
                action_events: data.action_events,
                status: data.status,
              });
              this.pageLoader(false);
            })
            .catch(() => {
              this.dialog = false;
              this.pageLoader(false);
            });
        }
        // this.resetFormData();
        this.dialog = true;
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
        // this.dialog = !this.dialog;
        this.dialog = false;
      } else {
        this.loadDataFromServer();
      }
    },
    async getData(value) {
      let data = { q: value, only_enabled: true };
      if (this.isClient) {
        data.client = this.$store.getters.currentUser.data.id;
      } else {
        data.client = this.formData.customer_id;
      }
      if (this.formData.organization_id) {
        data.organization_id = this.formData.organization_id;
      }

      await fetch(`${process.env.VUE_APP_BASE_URL}/skus/search_sku`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.skus;
        })
        .finally(() => (this.isLoading = false));
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("minValue" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].minValue &&
          errors.push("Value should be greater than 0");
      }
      if ("maxValue" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].maxValue &&
          errors.push("Value should be less than 5");
      }
      if ("url" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].url && errors.push("This url is invalid");
      }
      return errors;
    },
    submitForm() {
      // console.log("custom_header_value", this.formData);
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // if (this.$store.getters.isClient) delete this.formData.customer_id;

      const payload = { ...this.formData, volume: this.volume };

      // if (
      //   this.serverData.hasOwnProperty(
      //     "organizations"
      //   )
      // ) {
      //   payload.organization_id = this.formData.organization_id;
      // }

      let actionUrl = "store";
      if (this.actionType) {
        actionUrl = "update";
        payload.id = this.formData.id;
      }
      this.pageLoader(true);
      ApiService.post(
        `/warehouse_management/custom/uom_conversions/${actionUrl}`,
        payload
      )
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetFormData() {
      this.isLoading = false;
      this.search = null;
      this.entries = [];
      this.typingTimer = null;
      this.formData = {
        organization_id: null,
        customer_id: null,
        sku_id: null,
        uom_id: null,
        qty: null,
        base_uom_id: null,
        length: null,
        width: null,
        height: null,
        pack_material: null, //text
        volume: null,
        volume_uom_id: null,
        unit_price: null,
        currency_id: null,
        net_weight: null,
        gross_weight: null,
        weight_uom_id: null,
      };
    },
  },
  watch: {
    search(val) {
      clearTimeout(this.typingTimer);
      if (!val) {
        return;
      }
      this.typingTimer = setTimeout(() => {
        this.isLoading = true;
        this.getData(val.trim());
      }, 1500);
    },
  },
  computed: {
    serverData() {
      return this.$store.getters.getUOMCONVERSIONActionsDialogServerData;
    },
    //if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    skuItems: function () {
      return this.entries;
    },
    organization_idErrors: function () {
      return this.handleFormValidation("organization_id");
    },
    customer_idErrors: function () {
      return this.handleFormValidation("customer_id");
    },
    sku_idErrors: function () {
      return this.handleFormValidation("sku_id");
    },
    uom_idErrors: function () {
      return this.handleFormValidation("uom_id");
    },
    qtyErrors: function () {
      return this.handleFormValidation("qty");
    },
    base_uom_idErrors: function () {
      return this.handleFormValidation("base_uom_id");
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    volume: function () {
      const { width, height, length } = this.formData;
      if (width && height && length) return width * height * length;
      else return null;
    },
  },
};
</script>
