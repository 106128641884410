<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000px"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">
          {{ $t("edit_warehouse") }}
        </h3>
        <div class="btn btn-sm btn-icon btn-active-light" @click="toggleModal">
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <div class="row ma-0 pa-0">
          <div class="h-75px col-12 col-sm-4">
            <v-text-field
              v-model="formData.name"
              :label="$t('name')"
              outlined
              dense
              required
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-sm-4">
            <v-text-field
              v-model="formData.system_code"
              :label="$t('system_code')"
              outlined
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-md-4" v-if="!$store.getters.isClient">
            <v-autocomplete
              dense
              outlined
              v-model="formData.client_id"
              :items="serverData.clients"
              item-name="text"
              item-value="index"
              :label="$t('sellers')"
              clearable
              :error-messages="client_idErrors"
              @input="$v.formData.client_id.$touch()"
              @blur="$v.formData.client_id.$touch()"
            ></v-autocomplete>
          </div>

          <div class="h-75px col-12">
            <v-text-field
              v-model="formData.description"
              :label="$t('description')"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-4">
            <v-text-field
              v-model="formData.branch_id"
              :label="$t('branch_id_label')"
              clearable
              outlined
              dense
              :error-messages="branch_idErrors"
              @input="$v.formData.branch_id.$touch()"
              @blur="$v.formData.branch_id.$touch()"
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-4">
            <VueTelInputVuetify
              v-model="formData.phone"
              :label="$t('phone')"
              type="number"
              @change="() => validateMinValue('phone', 0, 'formData')"
              clearable
              outlined
              dense
              :inputOptions="{ showDialCode: false, tabindex: 0 }"
              mode="international"
              disabledFetchingCountry
              defaultCountry="AE"
              :error-messages="phoneErrors"
              @input="$v.formData.phone.$touch()"
              @blur="$v.formData.phone.$touch()"
            ></VueTelInputVuetify>
          </div>

          <div class="col-12 col-sm-4">
            <v-text-field
              v-model="formData.short_address"
              :label="$t('short_address')"
              clearable
              outlined
              dense
              append-icon="mdi-barcode-scan"
              @click:append="searchShortAddress"
              @keyup.enter="searchShortAddress"
              hint="Press enter to search after entering short address"
            ></v-text-field>
          </div>

          <!-- address -->
          <div class="col-12 col-sm-6 col-md-3">
            <v-autocomplete
              @change="onCountryChange"
              v-model="$v.formData.country.$model"
              :label="$t('country')"
              item-text="text"
              item-value="index"
              :items="serverData.countries"
              clearable
              outlined
              dense
              :error-messages="countryErrors"
              @input="$v.formData.country.$touch()"
              @blur="$v.formData.country.$touch()"
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <v-combobox
              v-model="$v.formData.state.$model"
              :search-input.sync="state_text"
              :items="serverData.states"
              :disabled="isStatesLoaded"
              item-text="title"
              item-value="title"
              :label="$t('state')"
              outlined
              required
              dense
              :error-messages="stateErrors"
              @input="$v.formData.state.$touch()"
              @blur="$v.formData.state.$touch()"
              @change="onStateChange"
              @keyup="updateValue('state')"
            >
              <template v-slot:append-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'state',
                            formData.country,
                            onCountryChange,
                            state_text
                          )
                        "
                      >
                        {{ $t("create_new") }}
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <v-combobox
              v-model="$v.formData.city.$model"
              :search-input.sync="city_text"
              :items="serverData.cities"
              :disabled="isCitiesLoaded"
              item-text="title"
              item-value="title"
              :label="$t('city')"
              persistent-hint
              outlined
              dense
              :error-messages="cityErrors"
              @input="$v.formData.city.$touch()"
              @blur="$v.formData.city.$touch()"
              @change="onCityChange"
              @keyup="updateValue('city')"
            >
              <template v-slot:append-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'city',
                            formData.state.id,
                            onStateChange,
                            city_text
                          )
                        "
                      >
                        {{ $t("create_new") }}
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <v-combobox
              :disabled="isAreasLoaded"
              v-model="formData.area"
              :search-input.sync="area_text"
              :items="serverData.areas"
              :rules="[validationValueCheck('area')]"
              item-text="title"
              item-value="title"
              :label="$t('area')"
              persistent-hint
              outlined
              dense
              @keyup="updateValue('area')"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'area',
                            formData.city.id,
                            onCityChange,
                            area_text
                          )
                        "
                      >
                        {{ $t("create_new") }}
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>

          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.address"
              :label="$t('address')"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.address_2"
              :label="$t('address_2')"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.street"
              :label="$t('street')"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.postal_code"
              :label="$t('postal_code')"
              clearable
              outlined
              dense
              :error-messages="postal_codeErrors"
              @input="$v.formData.postal_code.$touch()"
              @blur="$v.formData.postal_code.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <v-text-field
              v-model="formData.building_no"
              :label="$t('building_no')"
              clearable
              outlined
              dense
              min="0"
              type="number"
              @change="() => validateMinValue('building_no', 0, 'formData')"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <v-text-field
              v-model="formData.unit_no"
              :label="$t('unit_no')"
              clearable
              outlined
              dense
              min="0"
              type="number"
              @change="() => validateMinValue('unit_no', 0, 'formData')"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <v-text-field
              v-model="formData.additional_no"
              :label="$t('additional_no')"
              clearable
              outlined
              dense
              min="0"
              type="number"
              @change="() => validateMinValue('additional_no', 0, 'formData')"
            ></v-text-field>
          </div>
          <!-- end address -->
        </div>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mx-3 px-5 py-3 ls1"
            @click="resetEditForm"
          >
            {{ $t("clear") }}
          </button>
          <button
            type="submit"
            class="btn btn--export-filter px-5 py-3 ls1"
            @click="submitEditForm"
          >
            {{ $t("submit") }}
          </button>
        </div>
      </div>
    </div>
    <CreateLocation
      :location-id="getLocationId"
      :location-type="getLocationType"
      :location-function="getLocationFunc"
      :locationSetValue="setTextValue"
      :initValue="getLocationInitValue"
      ref="location"
    ></CreateLocation>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import validationMixin from "@/own/mixins/validationMixin.js";
import { required, requiredIf } from "vuelidate/lib/validators";
import CreateLocation from "./CreateLocation.vue";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "EditItem",
  mixins: [validationMixin, fieldValueValidation],
  components: { CreateLocation, VueTelInputVuetify },
  validations: {
    formData: {
      // system_code: { required },
      name: { required },
      country: { required },
      city: { required },
      state: { required },
      postal_code: { required },
      phone: { required },
      branch_id: { required },
      // address_2: { required },
      client_id: {
        required: requiredIf(function () {
          return !this.$store.getters.isClient;
        }),
      },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      client_id: null,
      system_code: null,
      name: null,
      description: null,
      country: null,
      city: null,
      state: null,
      area: null,
      address: null,
      address_2: null,
      short_address: null,
      street: null,
      postal_code: null,
      building_no: null,
      unit_no: null,
      additional_no: null,
      phone: null,
      branch_id: null,
    },
    // address related
    state_text: null,
    city_text: null,
    area_text: null,

    location_type: null,
    locaiton_id: null,
    location_function: null,
    location_initValue: null,

    old_address: {
      state: null,
      city: null,
      area: null,
    },
  }),
  methods: {
    // address related
    validationValueCheck(type) {
      let validation = null;
      switch (type) {
        case "state":
          try {
            if (this.state_text == "" || !this.state_text) {
              validation = null;
              break;
            } else if (
              this.serverData.states.filter(
                (state) => state.title == this.state_text
              ).length === 0
            ) {
              validation = this.$t("please_select_valid_state");
              this.serverData.cities = null;
              this.serverData.areas = null;
            }
          } catch {
            break;
          }

          break;
        case "city":
          try {
            if (this.city_text == "" || !this.city_text) {
              validation = null;
              break;
            } else if (
              this.serverData.cities.filter(
                (city) => city.title == this.city_text
              ).length === 0
            ) {
              validation = this.$t("please_select_valid_city");
              this.serverData.areas = null;
            }
          } catch {
            break;
          }

          break;
        case "area":
          try {
            if (this.area_text == "" || !this.area_text) {
              validation = null;
              break;
            } else if (
              this.serverData.areas.filter(
                (area) => area.title == this.area_text
              ).length === 0
            ) {
              validation = this.$t("please_select_valid_area");
            }
          } catch {
            break;
          }

          break;
      }
      return validation;
    },
    updateValue(type) {
      let timer;
      const waitTime = 1250;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, waitTime);
    },
    oldValueText(type) {
      let message = "";
      switch (type) {
        case "state":
          if (this.old_address.state) {
            message = `Received: ${this.old_address.state}`;
          }

          break;
        case "city":
          if (this.old_address.city) {
            message = `Received: ${this.old_address.city}`;
          }
          break;
        case "area":
          if (this.old_address.area) {
            message = `Received: ${this.old_address.area}`;
          }
          break;
      }
      return message;
    },
    onBlurValueCheck(type) {
      switch (type) {
        case "state":
          if (
            this.serverData.states.filter(
              (state) => state.title == this.state_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.state_text = "";
              this.serverData.cities = null;
              this.serverData.areas = null;
            }, 1000);
          } else {
            this.onStateChange(
              this.serverData.states.filter(
                (state) => state.title == this.state_text
              )[0]
            );
          }

          break;
        case "city":
          if (
            this.serverData.cities.filter(
              (city) => city.title == this.city_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.city_text = "";
              this.serverData.areas = null;
            }, 1000);
          } else {
            this.onCityChange(
              this.serverData.cities.filter(
                (city) => city.title == this.city_text
              )[0]
            );
          }
          break;
        case "area":
          if (
            this.serverData.areas.filter((area) => area.title == this.area_text)
              .length === 0
          ) {
            setTimeout(() => {
              // this.area_text = "";
            }, 2000);
          } else {
            this.formData.area = this.serverData.areas.filter(
              (area) => area.title == this.area_text
            )[0].title;
          }
          break;
      }
    },
    setTextValue(type, value = "") {
      switch (type) {
        case "state":
          this.state_text = value;
          if (value !== "") {
            this.formData.state = this.serverData.states.filter(
              (state) => state.title == this.state_text
            )[0];
            this.onStateChange(
              this.serverData.states.filter(
                (state) => state.title == this.state_text
              )[0].title
            );
          }

          break;
        case "city":
          this.city_text = value;
          if (value !== "") {
            this.formData.city = this.serverData.cities.filter(
              (city) => city.title == this.city_text
            )[0];
            this.onCityChange(
              this.serverData.cities.filter((city) => city.title == value)[0]
                .title
            );
          }

          break;
        case "area":
          this.area_text = value;

          if (value !== "") {
            try {
              this.formData.area = this.serverData.areas.filter(
                (area) => area.title == this.area_text
              )[0];
            } catch {
              this.formData.area = null;
            }
          }

          break;
      }
    },
    createNewItem(type, id, func, value) {
      // console.log(type, id, func);
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    searchShortAddress() {
      if (this.formData.short_address) {
        this.pageLoader(true);
        ApiService.post("/address/spl/short", {
          short: this.formData.short_address,
        })
          .then(async ({ data }) => {
            this.formData.country = data.address.country_id;

            this.serverData.states = data.states;
            this.serverData.cities = data.cities;
            this.serverData.areas = data.areas;
            const state = data.states.find(
              (state) => state.id === data.address.state_id
            );

            this.formData.state = { ...state };

            await this.onStateChange(state);

            if (data.address.city_title) {
              const city = data.cities.find(
                (city) => city.id === data.address.city_id
              );

              this.formData.city = { ...city };

              await this.onCityChange(city);
              this.city_text = String(data.address.city_title);
            } else {
              this.formData.city = null;
              this.city_text = null;
            }

            if (data.address.area_title) {
              this.formData.area = String(data.address.area_title);
              this.area_text = String(data.address.area_title);
            } else {
              this.formData.area = null;
              this.area_text = null;
            }

            this.formData.address = data.address.address;
            this.formData.address_2 = data.address.address_2;
            this.formData.building_no = data.address.building_no;
            this.formData.additional_no = data.address.additional_no;
            this.formData.street = data.address.street;
            this.formData.unit_no = data.address.unit_no;
            this.formData.postal_code = data.address.postal_code;
          })
          .finally(() => {
            this.pageLoader(false);
          });
      }
    },
    async onCountryChange(val, safe = true, is_id = false) {
      if (val) {
        this.pageLoader(true);
        let data = is_id ? { country: val } : { country: val };
        await ApiService.post("/address/states/search", data)
          .then((response) => {
            this.serverData.states = response.data.states;
            if (!safe) {
              this.formData.state = null;
              // this.address.city = null;
              // this.address.area = null;
              this.serverData.cities = null;
              this.serverData.areas = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            if (!safe) {
              this.formData.state = null;
              // this.address.city = null;
              // this.address.area = null;
              this.serverData.cities = null;
              this.serverData.areas = null;
            }

            this.dialog = false;
          });
      }
    },
    async onStateChange(val, safe = true, is_id = false) {
      if (val) {
        let item = null;
        if (is_id) {
          try {
            item = this.serverData.states.filter((state) => state.id == val)[0];
          } catch {
            return;
          }
        } else {
          try {
            if (typeof val === "object" && val !== null) {
              item = this.serverData.states.filter(
                (state) => state.title == val.title
              )[0];
            } else {
              item = this.serverData.states.filter(
                (state) => state.title == val
              )[0];
            }
          } catch {
            return;
          }
        }
        if (!item) {
          return;
        }

        this.formData.state = item;
        let data = { state: item.id };
        this.pageLoader(true);
        await ApiService.post("/address/cities/search", data)
          .then((response) => {
            this.serverData.cities = response.data.cities;
            if (!safe) {
              this.formData.city = null;
              // this.address.area = null;
              this.serverData.areas = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            if (!safe) {
              this.formData.city = null;
              // this.address.area = null;
              this.serverData.areas = null;
            }
            this.dialog = false;
          });
      }
    },
    async onCityChange(val, safe = true, is_id = false) {
      if (val) {
        let item = null;
        if (is_id) {
          try {
            item = this.serverData.cities.filter((city) => city.id == val)[0];
          } catch {
            return;
          }
        } else {
          try {
            if (typeof val === "object" && val !== null) {
              item = this.serverData.cities.filter(
                (city) => city.title == val.title
              )[0];
            } else {
              item = this.serverData.cities.filter(
                (city) => city.title == val
              )[0];
            }
          } catch {
            return;
          }
        }

        if (!item) {
          return;
        }
        this.formData.city = item;
        this.pageLoader(true);
        let data = { city: item.id };
        await ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.serverData.areas = response.data.areas;
            if (!safe) {
              this.formData.area = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            if (!safe) {
              this.formData.area = null;
            }
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
    // addresss related end
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/warehouse_management/warehouse_basic/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    convertToFormData() {
      // let data = new FormData();
      // Object.entries(this.formData).forEach((item) => {
      //   if (
      //     (item[1] && item[0] !== "image" && item[0] !== "photo") ||
      //     item[1] === 0
      //   ) {
      //     if (Array.isArray(item[1])) {
      //       item[1].forEach((it) => data.append(`${item[0]}[]`, it));
      //     } else {
      //       data.append(item[0], item[1]);
      //     }
      //   } else if (item[1] && (item[0] === "image" || item[0] === "photo")) {
      //     data.append(item[0], item[1][0]);
      //   }
      // });
      // return data;
      return JSON.parse(JSON.stringify(this.formData));
    },
    submitEditForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.convertToFormData();
        data.state = this.formData.state.id;
        data.city = this.formData.city.id;
        if (this.formData.area) {
          data.area = this.formData.area.id;
        }
        ApiService.post("/warehouse_management/warehouse_basic/update", data)
          .then(() => {
            SwalService.successMessage({
              title: this.$t("created"),
              html: this.$t("courier_data_has_been_updated"),
            });

            this.toggleModal();
            this.pageLoader(false);
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    async resetEditForm() {
      this.$v.$reset();
      const dataNames = ["formData"];
      if (this.serverData.warehouse) {
        dataNames.forEach(async (dataName) => {
          let copy = { ...this.serverData.warehouse };
          await Object.entries(this[`${dataName}`]).forEach((localD) => {
            Object.entries(this.serverData.warehouse).forEach((serverD) => {
              if (localD[0] === serverD[0] && localD[0] !== "image") {
                this[`${dataName}`][localD[0]] = copy[serverD[0]];
              }
            });
          });
        });
        this.old_address = {
          state: null,
          city: null,
          area: null,
        };

        if (this.serverData.warehouse.country) {
          await this.onCountryChange(
            this.serverData.warehouse.country,
            true,
            true
          ).then(async () => {
            if (this.serverData.warehouse.state) {
              this.old_address["state"] = this.serverData.states.find(
                (state) => state.id == this.serverData.warehouse.state
              ).title;
              await this.onStateChange(
                this.serverData.warehouse.state,
                true,
                true
              ).then(async () => {
                if (this.serverData.warehouse.city) {
                  this.old_address["city"] = this.serverData.cities.find(
                    (city) => city.id == this.serverData.warehouse.city
                  ).title;
                  await this.onCityChange(
                    this.serverData.warehouse.city,
                    true,
                    true
                  ).then(() => {
                    if (this.serverData.warehouse.area) {
                      this.formData.area = this.serverData.areas.find(
                        (area) => area.id == this.serverData.warehouse.area
                      );
                      this.old_address["area"] = this.formData.area.title;
                    }
                  });
                }
              });
            }
          });
        }
      } else {
        this.formData = {
          id: null,
          system_code: null,
          name: null,
          description: null,
          country: null,
          city: null,
          state: null,
          area: null,
          address: null,
          address_2: null,
          short_address: null,
          street: null,
          postal_code: null,
          building_no: null,
          unit_no: null,
          additional_no: null,
          phone: null,
          branch_id: null,
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        (this.serverData = {
          cities: [],
          areas: [],
          countries: [],
          states: [],
        }),
          this.resetEditForm();
      } else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    // address related
    getLocationType() {
      return this.location_type;
    },
    getLocationId() {
      return this.locaiton_id;
    },
    getLocationFunc() {
      return this.location_function;
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
    isStatesLoaded: function () {
      return !this.serverData.states;
    },
    isCitiesLoaded: function () {
      return !this.serverData.cities;
    },
    isAreasLoaded: function () {
      return !this.serverData.areas;
    },
    client_idErrors() {
      return this.handleFormValidation("client_id", this);
    },

    cityErrors: function () {
      let error = this.handleFormValidation("city", this);
      let ms = this.validationValueCheck("city");
      let old = this.oldValueText("city");
      // console.log(error, ms, old, this.old_address);
      if (ms && error[0]) {
        // error.push(ms);
        if (old) {
          error[0] = ms + " " + error[0] + ". " + old;
        } else {
          error[0] = ms + " " + error[0];
        }
      } else if (ms) {
        if (old) {
          error[0] = ms + " " + old;
        } else {
          error[0] = ms + " ";
        }
      }
      return error;
    },
    countryErrors: function () {
      return this.handleFormValidation("country", this);
    },
    stateErrors: function () {
      let error = this.handleFormValidation("state", this);
      let ms = this.validationValueCheck("state");
      let old = this.oldValueText("state");
      // console.log(error, ms, old, this.old_address);
      if (ms && error[0]) {
        // error.push(ms);
        if (old) {
          error[0] = ms + " " + error[0] + ". " + old;
        } else {
          error[0] = ms + " " + error[0];
        }
      } else if (ms) {
        if (old) {
          error[0] = ms + " " + old;
        } else {
          error[0] = ms + " ";
        }
      }
      return error;
    },
    // end address
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    // system_codeErrors() {
    //   return this.handleFormValidation("system_code", this);
    // },
    postal_codeErrors() {
      return this.handleFormValidation("postal_code", this);
    },
    phoneErrors() {
      return this.handleFormValidation("phone", this);
    },
    branch_idErrors() {
      return this.handleFormValidation("branch_id", this);
    },
    // addressErrors() {
    //   return this.handleFormValidation("address", this);
    // },
    // address_2Errors() {
    //   return this.handleFormValidation("address_2", this);
    // },
  },
};
</script>

<style scoped>
.editorStyle {
  max-height: 70vh;
  overflow: hidden;
}
</style>
